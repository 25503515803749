.ant-card-head {
  color: #fff !important;
  background-color: #088f42 !important;
  font-size: 14px !important;
  text-align: center !important;
}

a {
  font-weight: normal;
}

.normalfontweight {
  font-weight: normal;
}

.borderBox {
  border: 1px solid #088f42 !important;
  color: #545454;
  padding-left: 10px;
}

.heightAKABalikbayan {
  height: 228px !important;
}

.commercialTxt {
  color: #fff !important;
}

.BusinessfreightDiv {
  padding-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.talkBusiness {
  padding-right: 10px;
}

@media screen and (min-width: 1024px) {
  .heightBusinessFreight {
    height: 213px !important;
  }
}

@media screen and (max-width: 1024px) {
  .heightBusinessFreight {
    height: 213px !important;
  }

  .pWebPageHeaderText {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 25px;
  }
}

@media screen and (max-width: 768px) {
  .heightBusinessFreight {
    height: 213px !important;
  }
  .pWebPageHeaderText {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 18px;
  }
}

@media screen and (min-width: 1441px) {
  .pWebPageHeaderText {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 30px;
  }
}

@media screen and (max-width: 425px) {
  .heightBusinessFreight {
    height: 200px !important;
  }
}

.mainHeaderSmall,
.webPageSmallMenu {
  display: none;
}

@media screen and (max-width: 430px) {
  .mainHeaderSmall,
  .webPageSmallMenu {
    display: block;
  }

  .pWebPageHeaderTextSmall {
    text-align: left;
    font-size: 16px;
    margin: 6px;
    /* font-weight: 600; */
  }

  .mainHeaderHide,
  .webPageBigMenu {
    display: none;
  }
}

@media screen and (max-width: 430px) {
  .ant-layout-header {
    padding-left: 0px !important;
    padding-right: 25px !important;
  }

  .logo {
    max-width: 60% !important;
  }

  .logo-and-menu-container {
    display: flex;
    align-items: center;
  }

  .logo {
    margin-right: auto; /* Adjust the margin as needed */
  }

  .menu-container {
    flex-grow: 1;
    text-align: right;
    font-size: 26px;
    color: #088f42;
  }

  .ant-drawer-close {
    color: #088f42 !important;
    font-size: 15px !important;
    margin-right: 0px !important;
  }

  .ant-drawer-header-close-only {
    padding: 10px !important;
  }
}

.ant-drawer-content-wrapper,
.ant-drawer-content {
  height: auto !important;
  border-bottom-right-radius: 10px;
}

.tAndClink {
  text-decoration: underline;
}

.webPageHeaderTextAboutUs {
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  padding-left: 20px;
}

.headertextAboutUs {
  margin-bottom: 10px;
  margin-top: 10px;
}

.bodyAboutUs {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
}

.custom-title {
  line-height: 20px;
  padding-top: 5px;
}

.custom-title-pd {
  padding-left: 10px;
}

.subContentTOC {
  padding-left: 30px;
  color: #545454;
}

.ul-TOC {
  list-style: none; /* Remove default list styles */
}

.ul-TOC1::before {
  content: none; /* Remove the default marker */
}

.scrollable-modal-content-TOC {
  max-height: 70vh; /* Adjust the value as needed */
  overflow-y: auto;
}

.toc-margin {
  margin-top: 0px;
}
.ant-modal-close {
  top: 9px !important;
  right: 5px !important;
}

.aboutVM {
  margin-top: 0px !important;
}

.aboutVMH1 {
  margin-bottom: 0px !important;
}

@media screen and (min-width: 1024px) {
  .howItWorksCol {
    padding-left: 20px !important;
  }
}

@media screen and (min-width: 1440px) {
  .howItWorksCol2 {
    padding-left: 77px !important;
  }
  .howItWorksCol {
    padding-left: 100px !important;
  }
}

.footer-bck {
  background-color: #f6f2f1;
  padding-left: 18px;
}

.trackingHome {
  display: flex;
}

.trackingTextboxHome {
  padding-right: 10px;
}

.trackingBtnHome {
  margin-left: 10px;
}

.priceSmall {
  display: none;
}
@media screen and (min-width: 1024px) {
  .serviceImg {
    height: 250px !important; /* Adjust the height as needed */
    /*  object-fit: cover;  Maintain aspect ratio and cover the container */
  }
}

@media screen and (max-width: 1440px) {
  .serviceImg {
    height: 200px !important; /* Adjust the height as needed */
  }

  .firstDivHome {
    padding-left: 150px !important;
  }
}

@media screen and (max-width: 375px) {
  .serviceImg {
    height: 144px !important; /* Adjust the height as needed */
  }
}

.ant-card-head-title {
  padding-top: 10px;
  padding-bottom: 10px;
}

.cardOurservice .ant-card-body {
  padding: 0px !important;
}

.cardKFSPartner .ant-card-body {
  padding: 0px !important;
}
@media screen and (min-width: 1024px) {
  .service-import {
    height: 300px;
    width: 100%;
  }

  .kfs-partner-store {
    height: 510px;
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .service-import {
    height: 250px;
    width: 100%;
  }
  .firstDivHome {
    padding-left: 25px !important;
  }

  .kfs-partner-store {
    height: 350px;
    width: 100%;
  }

  .ulprice {
    margin-top: 10px;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 768px) {
  .service-import {
    height: 180px;
    width: 100%;
  }

  .padding-smlV {
    margin-top: 15px;
  }

  .kfs-partner-store {
    height: 381px;
    width: 100%;
  }

  .ulprice {
    margin-top: 10px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 425px) {
  .Businessfreight {
    margin-top: 20px;
  }
  .kfs-partner-store {
    height: 435px;
    width: 100%;
  }

  .divTxtAsianStore {
    min-height: auto;
  }
}

@media screen and (max-width: 375px) {
  .service-import {
    height: 200px;
    width: 100%;
  }

  .divTxtAsianStore {
    min-height: auto !important;
  }
}

@media screen and (min-width: 1441px) {
  .service-import {
    height: 300px;
    width: 100%;
  }

  .firstDivHome {
    padding-left: 150px !important;
  }

  .kfs-partner-store {
    height: 600px;
    width: 100%;
  }
}

.border-visionmission {
  border: 1px solid #088f42;
  border-radius: 9px;
}

.border-visionmission-left {
  margin-left: 5px !important;
}

.bodyFAQs {
  padding-left: 20px;
  padding-right: 20px;
}

.headertextFAQs {
  margin-bottom: 10px;
  margin-top: 10px;
}

@media screen and (max-width: 320px) {
  .headertextFAQs {
    font-size: 18px;
  }

  .kfs-partner-store {
    height: 350px;
    width: 100%;
  }
  .divTxtAsianStore {
    min-height: auto !important;
  }

  .priceP {
    font-size: 13px;
  }
}

@media screen and (min-width: 425px) {
  .divTxtAsianStore {
    min-height: auto !important;
  }

  .divTxtAsianStore {
    min-height: 132px;
  }
}

@media screen and (min-width: 426px) {
  .divTxtAsianStore {
    min-height: 132px !important;
  }
}

.verticalBar {
  margin: 0 5px; /* Adjust the spacing as needed */
  color: #088f42;
  font-weight: bold;
}

.cardKFSPartner {
  margin-bottom: 30px !important;
}

.melsydlbl {
  font-size: 15px;
}

.listedprice {
  margin-bottom: 5px !important;
}
