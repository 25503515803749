@media print {
  .print-container > div {
    page-break-before: always; /* Ensure each container starts on a new page */
  }

  /* Define A4 size */
  @page {
    size: A4;
    margin: 0; /* Reset default margin */
  }

  /* Set margins for content within each page */
  .print-container > div {
    margin: 20px; /* Adjust as needed */
  }
  .no-print {
    display: none;
  }

  .ant-layout-content {
    padding: 0px !important;
  }
  .logo1 {
    width: 90% !important;
  }
}

.logo1 {
  width: 80%;
}

.headerTd2 {
  width: 72%;
}

.Kalinga {
  font-size: 18px;
  font-weight: bold;
}

.tdp {
  padding: 5px 10px 5px 10px;
}

.signature {
  width: 24%;
}

.mg-l {
  margin-left: 10px;
}

.mg-r {
  margin-right: 10px;
}
